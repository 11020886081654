@import "./globals";

.form {
  margin: 2rem auto 1.5rem;
}
.form-group {
  margin-bottom: 1.525rem;
  position: relative;
  .form-control,
  .custom-control,
  .custom-select {
    border-radius: 6px;
    height: auto;
    min-height: 54px;
    max-height: 54px;
    display: flex;
    align-items: center;
    color: $grey;
    caret-color: $secondary;
    padding: 0.815rem 1rem;
    margin-bottom: 0rem;
    font-weight: 500;
    border-color: rgba(13, 18, 42, 0.2);
    &:focus {
      box-shadow: none;
      border-color: $primary;
      color: $primary;
    }
    &[disabled],
    &[readonly] {
      background: transparent;
      color: $grey !important;
      opacity: 0.85;
    }
  }
  textarea {
    &.form-control,
    &.custom-control {
      max-height: none;
    }
  }
  &.floating {
    .form-label {
      color: $grey;
      font-weight: 400;
      margin: 0;
      position: absolute;
      top: 50%;
      left: 1rem;
      pointer-events: none;
      user-select: none;
      transform: translate(0%, -50%);
      // max-width: 90%;
      @include transition(0.2s);
    }
    .form-control {
      color: $primary;
      &:focus,
      &:valid,
      &:disabled,
      &:read-only {
        padding: 1.85rem 1rem 0.65rem;
      }
      &:focus + .form-label,
      &:valid + .form-label,
      &:disabled + .form-label,
      &:read-only + .form-label {
        top: 30%;
        line-height: normal;
        font-size: 12px;
      }
    }
    &.textarea-wrap {
      .form-label {
        top: 1.75rem;
      }
      textarea:focus + .form-label,
      textarea:valid + .form-label,
      textarea:disabled + .form-label,
      textarea:read-only + .form-label {
        // top: 1.25rem;
        top: 1rem;
        background: $white;
        padding: 10px 0 5px;
        width: 93.5%;
      }
      .form-control {
        min-height: 162px;
      }
    }
  }
  &.with-icon {
    position: relative;
    .input-icon {
      height: auto;
      width: 1.5rem;
      position: absolute;
      top: 48.95%;
      left: 1rem;
      pointer-events: none;
      user-select: none;
      transform: translate(0%, -50%);
    }
    .form-control,
    .custom-control,
    .custom-select {
      padding-left: 3.25rem;
    }

    &.icon-right{
      .input-icon{
        right: 1rem;
        left: unset !important;
      }
      .form-control,
      .custom-control,
      .custom-select {
        padding-left: 1rem;
      }
    }
  }
  .invalid-feedback {
    position: relative;
    // top: 100%;
    // transform: translate(-50%, -50%);
    // left: 50%;
    // margin: 0.85rem auto;
  }
}

.custom-checkbox {
  @extend .form-control;
  margin-left: 0.65rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  // padding: 0;
}
.custom-checkbox .custom-control-label {
  padding-left: 0.65rem;
  width: 100%;
  flex: 0 0 100%;
  @include transition(0.2s);
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
  }
  &::before {
    background-color: #e5e3e8;
    border: #e5e3e8 solid 1px;
    box-shadow: none;
    top: -0.05rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  &::after {
    width: 1.5rem;
    height: 1.5rem;
    top: 0;
    background: no-repeat 50%/50% 45%;
  }
}
.custom-checkbox .custom-control-input {
  left: -0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #00e78e;
  background-color: #00e78e;
  box-shadow: none;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00e78e;
  box-shadow: none;
}
.custom-file {
  height: auto;
  .custom-file-input {
    height: auto;
    min-height: 12rem;
  }
  .custom-file-label {
    height: 100%;
    min-height: 12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed #cccfd1;
    // position: relative;
    &::after {
      content: none;
    }
  }
  &.compact {
    .custom-file-input,
    .custom-file-label {
      min-height: 7.75rem;
    }
  }
}

.file-drop-wrap {
  .file-drop-main {
    height: auto;
    min-height: 12rem;
    max-width: 12rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $white;
    border: 2px dashed #cccfd1;
    flex-direction: column;
    border-radius: 8px;
    color: $grey;
    font-weight: 400;
    flex: 1;
    transition: border 0.24s ease-in-out;
    padding: 1rem;
    text-align: center;
    margin: 1rem 0 1.35rem;
    overflow-x: hidden;
    cursor: pointer;
    @include transition(0.2s);
    position: relative;
    &:hover,
    &:focus,
    &:active {
      background: $background;
      outline: none;
      box-shadow: none;
    }
    &:hover {
      opacity: 0.8;
      background: $white;
    }
    img {
      width: auto;
      max-width: 100%;
      height: 4.5rem;
      &:not(.preview-img) {
        position: absolute;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        top: 50%;
        left: 50%;
        pointer-events: none;
        user-select: none;
        transform: translate(-50%, -50%);
        background: transparent;
        height: 100%;
        object-fit: contain;
      }
      &.audio-file-icon {
        max-height: 4.75rem;
      }
    }
    .input-label {
      margin: 1rem auto 0;
    }
  }
  .selected-file-name {
    word-break: break-word;
    color: $primary;
    font-size: 12px;
    margin: 0.5rem auto;
  }
  &.audio-drop {
    .file-drop-main {
      max-width: none;
      min-height: 7.75rem;
      .input-label {
        margin: 0.15rem auto 0;
        &.subtitle {
          font-size: 12px;
          margin-top: 0.35rem;
        }
      }
    }
    .selected-file-name {
      text-align: center;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
    }
  }
}
.socialLogin-wrapper .socialLogin  {
  div[role="button"] > div:nth-child(2){
    width: auto !important;

  }
  /* Style for the button */
  div[role="button"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* You can specify a fixed width here if needed */
  padding: 15px 20px; /* Adds padding to make the button wider */
  border-radius: 4px; /* Optional, for rounded corners */

}
  .googleLogin {
    border-radius: 2rem;
    padding: 0.75rem 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: normal;
    width: 47.25%;
    background: $white;
    color: $primary;
    border: 1px solid #cccfd1;
    @include transition(0.2s);
    &:hover,
    &:focus,
    &:active {
      background: $background;
      border-color: #cccfd1;
      text-decoration: none;
    }
    img {
      margin-right: 0.65rem;
      width: auto;
      @include max(767) {
        margin-right: 0.35rem;
      }
    }
    span {
      display: inline-block;
      line-height: 1.55;
      position: relative;
      top: 1px;
    }
  }
}
.socialLogin {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  width: 100%;
  align-items: center;


  // .facebookLogin {
  //   background: #406fd8;
  //   color: $white;
  //   height: 40px;
  //   border-radius: 4px;
  //   border: 1px solid #406fd8;
  //   box-shadow: none;
  //   justify-content: space-between;
  //   gap: 0.5rem;
  //   font-size: 14px;
  //   min-width: 177px;
  //   &:hover,
  //   &:focus,
  //   &:active {
  //     background: #2259c6;
  //     border-color: #2259c6;
  //     color: $white;
  //   }
  //   .imageContainer {
  //     background: white;
  //     border-radius: 50%;
  //     height: 25px;
  //     width: 25px;
  //   }
  //   img {
  //     position: relative;
  //     right: 1px;
  //     top: 2px;
  //   }
  // }
}
.forgot-password-link {
  text-align: right;
  a {
    color: $grey;
    &:hover,
    &:focus,
    &:active {
      color: $primary;
    }
  }
}
.char-limit {
  color: $grey;
  font-size: 12px;
  font-weight: 400;
  margin: -15px 0 0.65rem 0;
}
.remove-promo {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px;
  margin: 10px;
}
.form-group-heading {
  color: $primary;
  font-weight: 600;
  margin-bottom: 0.75rem;
}
.form-url {
  @extend .form-group-heading;
  color: $grey;
  margin: 0.75rem auto 0.35rem;
}
.form-group-subheading {
  @extend .form-url;
  margin-bottom: 0.5rem;
  margin-top: -0.45rem;
  font-weight: 500;
}
.radio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .form-radio {
    margin-bottom: 1.525rem;
    margin-right: 5px;
    text-align: center;
  }
  .floating {
    width: 100%;
  }
}
.color-picker-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .color-wrap {
    width: 3.375rem;
    height: 3.375rem;
    opacity: 1;
    border-radius: 8px;
    margin-bottom: 1.525rem;
    margin-right: 1.35rem;
    border: 1px solid #cccfd1;
    cursor: pointer;
    @include transition(0.2s);
    &:hover {
      opacity: 0.8;
    }
  }
  .form-group {
    flex: 1;
    .form-control {
      cursor: pointer;
      text-transform: uppercase;
      @include transition(0.2s);
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .popover {
    position: absolute;
    z-index: 3;
    top: 90%;
    max-width: none;
    border-color: $lightGrey;
    box-shadow: -1px 0px 1.5rem #4c484838;
  }
  .cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  .twitter-picker {
    box-shadow: none !important;
  }
}
.discoverFilterWrap {
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  @include max(594) {
    max-width: 100%;
  }
  .filterWrap {
    max-width: 280px;
    width: 100%;
    margin: 0.5rem 15px 2rem;
    @include max(767) {
      margin: 0.5rem 8px 2rem;
    }
    @include max(594) {
      margin: 10px auto;
      &:last-child {
        margin-bottom: 20px;
      }
    }

    .search-select__value-container {
      padding: 0.388rem 1rem;
    }
  }
}
.search-select-wrap {
  .search-select__control {
    max-height: 54px;
    border-color: rgba(13, 18, 42, 0.2);
    font-weight: 400;
    border-radius: 6px;
    box-shadow: none;
    cursor: pointer;
    @include transition(0.2s);
    &:hover {
      border-color: rgba(13, 18, 42, 0.2);
      opacity: 0.75;
    }
    &:focus {
      border-color: $primary;
    }
    &.search-select__control--menu-is-open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .search-select__value-container{
      .search-select__placeholder{
        text-align: left;
      }
    }
  }
  .search-select__menu-list {
    background: $background;
  }
  .search-select__option {
    cursor: pointer;
  }
  .search-select__value-container {
    padding: 0.688rem 1rem;
  }
  .search-select__indicator-separator {
    background: transparent;
  }
  .search-select__menu {
    z-index: 2;
    margin: 0 auto;
    border-radius: 6px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: none;
    border: 1px solid rgba(13, 18, 42, 0.2);
    border-top: 0;
    box-shadow: none;
  }
}

.invalid-feedback {
  position: relative;
  transform: translateY(-1.2rem);
  display: block;
  color: $error;
  &.custom-error {
    // display: block;
    color: $error;
  }
}
.custom-error{
  color: $error;
  margin-top: 0.2rem;
}
.form-group .form-control.is-invalid,
.was-validated .form-control:invalid,
.form-group .form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.custom-control-input.is-invalid ~ .custom-control-label::before,
.was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  background: none;
  border-color: $error;
  caret-color: $error;
}
.form-group .form-control.is-invalid + .form-label,
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: $error;
}
.form-row {
  margin: 0 auto;
  > .col {
    padding: 0;
    &:first-child {
      margin-right: 0.5rem;
    }
    &:last-child {
      margin-left: 0.5rem;
    }
  }
}
@include max(991) {
  .form-group {
    .custom-control {
      z-index: 0;
    }
  }
}

.custom-control.custom-switch {
  padding: 0;
  padding-left: 2.25rem;

  .custom-control-label::before {
    border-radius: 0.75rem;
    width: 2.5rem;
    height: 1.5rem;
  }

  .custom-control-label::after {
    height: 1.25rem;
    width: 1.25rem;
    border-radius: calc(1.25rem / 2);
    left: calc(-2.5rem + 6px);
  }

  .custom-control-input:checked~.custom-control-label::after {
    -webkit-transform: translateX(1rem);
    -moz-transform: translateX(1rem);
    transform: translateX(1rem);
  }
}


.MuiSelect-select{
  &:focus {
    background-color: transparent !important;
  }
}